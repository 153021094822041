import React from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';

import { useAddressVerification } from 'hooks/useAddressVerification';

import { ProfileObjectAddressPayload } from 'state/account/account.services';
import { AddressParts } from 'state/usps/usps.reducers';
import { accountAddAddressToProfileRoutine } from 'state/account/account.routines';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import AddAddressForm, { AddressVerificationFailureModalContent } from 'components/add-address-form/AddAddressForm';
import { FailureUpdateProfileModalContent } from '../intra-page-items/_profile-update-modal.item';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';

export default function AddNewAddress() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { verifyAddress } = useAddressVerification();

    const formSubmit = (values: ProfileObjectAddressPayload) => {
        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };
        verifyAddress({
            address,
            onSuccess: () => {
                const Zip5 = values.zipcode.length > 5 ? values.zipcode.slice(0, 5) : values.zipcode;
                const Zip4 = values.zipcode.length > 5 ? values.zipcode.slice(-4) : '';
                accountAddAddressToProfileRoutine.trigger({
                    ...values,
                    zipcode: Zip5,
                    zipcodeFour: Zip4,
                    onFailure: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                size: 'lg',
                                type: 'danger',
                                headerContent: (
                                    <BirdiModalHeaderDanger icon="alert" headerText={t('modals.updateProfile.error')} />
                                ),
                                bodyContent: (
                                    <FailureUpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                });
                navigate('/secure/profile/shipping-address');
            },
            onFailure: () => {
                dispatch(
                    openModal({
                        showClose: true,
                        bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                        ctas: [
                            {
                                label: t('modals.healthConditions.submit'),
                                variant: 'primary',
                                onClick: () => {
                                    dispatch(closeModal({}));
                                }
                            }
                        ]
                    })
                );
            }
        });
    };
    const formCancel = () => {
        navigate('/secure/profile/shipping-address');
    };

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.eyebrowText`)}
            title={t(`pages.profile.shippingAddress.title`)}
            heading={'Shipping Address'}
        >
            <Container fluid>
                <AddAddressForm
                    handleFormCancel={formCancel}
                    handleFormSubmit={formSubmit}
                    centerFormSubmit={false}
                    showSetAsDefault={true}
                />
            </Container>
        </ProfileLayout>
    );
}
